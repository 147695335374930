import React from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

export const Timeline = () => {
  return (
    <VerticalTimeline>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
        date="Marzo 2015"
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
      >
        <h3 className="vertical-timeline-element-title text-xl font-bold">Fundación de Échame una Pata SV</h3>
        <p>
          El proyecto de rescate animal nace con el propósito de ayudar a rescatistas independientes y refugios con alimento y medicinas.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="Diciembre 2018"
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
      >
        <h3 className="vertical-timeline-element-title text-xl font-bold">Apertura del Refugio</h3>
        <p>
          Se abren las puertas del refugio en el departamento de San Miguel, administrado por la Licda. Silvia Beatriz Ayala Granados, brindando un hogar a los perros más necesitados.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="2019"
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
      >
        <h3 className="vertical-timeline-element-title text-xl font-bold text-gray-800">Inicio del Plan Padrino</h3>
        <p>
          Se lanza el proyecto Plan Padrino, permitiendo el apadrinamiento de perros con US$10 mensuales, cubriendo sus necesidades básicas y los gastos del refugio.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="2020"
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
      >
        <h3 className="vertical-timeline-element-title text-xl font-bold text-gray-800">Expansión del Refugio</h3>
        <p>
          Con las donaciones recibidas, el refugio se expande para albergar a más perros y mejorar las instalaciones y servicios.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="2023"
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
      >
        <h3 className="vertical-timeline-element-title text-xl font-bold text-gray-800">+500 Adopciones Exitosas</h3>
        <p>
          Celebramos la adopción de 500 perros rehabilitados que han encontrado hogares amorosos.
        </p>
      </VerticalTimelineElement>
    </VerticalTimeline>
  )
}
