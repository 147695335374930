import { pr1 } from "../components/imagenes"

export const SectionContacto = () => {
  return (
    <section className="h-2/4 w-full relative flex flex-col justify-center items-center bg-gray-100" id="contacto">
      <div className='h-full w-full relative'>

        <div className='absolute w-full h-full md:flex backgroundimg' style={{backgroundImage:`url(${pr1})`}}></div>
        <div className='absolute w-full h-full md:flex' style={{backgroundColor:`rbg(0,0,0,0.3)`}}></div>

        <div className='absolute w-full h-full flex justify-center items-center flex-col'>
          <span className='text-6xl text-white font-semibold'>ECHAME UNA PATA</span>
          <span className='text-base text-lg text-white h-20'>Salvando y cambiando vidas con tu ayuda</span>
          <a href={"/contacto"} className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-400">CONTACTANOS</a>
        </div>

      </div>
    </section>
  )
}
