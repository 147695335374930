import React from 'react'
import '../styles/galleryCard.css'

export const GalleryCard = ({img, onClick}) => {
  return (
    <>
    <div className="gallery-card">
      <img className="gallery-img" onClick={onClick} src={img} alt="" />
    </div>
    </>
  )
}
