import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './app';
import { Dogs } from './pages/dogs';
import { Mision } from './pages/mision';
import { BrowserRouter } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import { Galeria } from './pages/galeria';
import { Contacto } from './pages/contacto';
import { PageNotFound } from './pages/error';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}></Route>
        <Route path="/plan-padrino" element={<Dogs />}></Route>
        <Route path="/mision" element={<Mision />}></Route>
        <Route path="/galeria" element={<Galeria />}></Route>
        <Route path="/contacto" element={<Contacto />}></Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      {/* <App /> */}
    </BrowserRouter>
  </React.StrictMode>
);
