import React from 'react'
import { Nav } from '../parts/nav'
import { Footer } from '../parts/footer'

export const PageNotFound = () => {
  return (
    <>
      <Nav />
      <section className='w-full h-3/4 relative bg-gray-100'>
        <div className='h-full w-full relative'>
          <div className='absolute w-full h-full flex flex-col justify-center items-center bg-gray-800'>
            <span className='text-6xl text-white font-semibold text-center'>
              404 - Página No Encontrada
            </span>
            <p className='text-sm text-center font-light text-white mt-2'>
              Lo sentimos, la página que estás buscando no existe. Verifica la URL o vuelve a la página principal.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
