import { CustomCard as Card } from '../components/cards'
import {Mision, Padrino, Adopta} from '../components/svg_container';

export const SectionNosotros = () => {
  return (
    <>

        <section className='h-auto w-full relative flex justify-center items-center py-6 bg-gray-100'>

            <div className="w-full h-full flex justify-center items-center p-10">
              <div className="flex flex-wrap justify-center">
                <Card
                  title="Nuestra Misión"
                  description="Desde 2015, ayudamos a rescatar y rehabilitar perros abandonados y maltratados, brindándoles una segunda oportunidad."
                  buttonText="Leer más"
                  Image={<Mision />}
                  funOnOlick={"/mision"}
                />
                <Card 
                  title="Plan Padrino"
                  description="Contribuye con US$10 mensuales y ayuda a cubrir las necesidades básicas de un perro en nuestro refugio."
                  buttonText="Apadrina"
                  Image={<Padrino />}
                  funOnOlick={"/plan-padrino"}
                />
                <Card 
                  title="Adopta"
                  description="Más de 100 perros rehabilitados están esperando encontrar un hogar amoroso. ¡Adopta hoy!"
                  buttonText="Contactanos"
                  funOnOlick={"/contacto"}
                  Image={<Adopta />}
                />
              </div>
            </div>

        </section>
    
    </>
  )
}
