import React from 'react'
import { Nav } from '../parts/nav'
import { msn } from '../components/imagenes';

import '../styles/carrusel.css';
import '../styles/index.css';
import { Footer } from '../parts/footer';

export const Mision = () => {
  return (
    <>
        <Nav />
        <section className='w-full h-3/4 relative bg-gray-100'>
            <div className='h-full w-full relative'>

              <div className='absolute w-full h-full md:flex backgroundimg' style={{backgroundImage:`url(${msn})`}}></div>
              <div className='absolute w-full h-full md:flex' style={{backgroundColor:`rgba(0,0,0,0.3)`}}></div>

              <div className='absolute w-full h-full flex justify-center items-center'>
                <span className='text-6xl text-white font-semibold text-center'>NUESTRA MISION</span>
              </div>

            </div>
        </section>
        <div className='w-full bg-gray-100 flex items-center justify-center py-16'>
            <div className='bg-white rounded-lg w-full max-w-2xl p-10 shadow-lg'>

                <div className='flex flex-col space-y-6'>
                    <p className='text-lg font-light text-gray-800'>
                        En Échame una Pata SV, nuestra misión es ofrecer una segunda oportunidad a los animales más necesitados. Desde marzo de 2015, nos dedicamos a apoyar a rescatistas independientes y refugios, proporcionándoles alimentos y medicinas esenciales.
                    </p>
                    <p className='text-lg font-light text-gray-800'>
                        En diciembre de 2018, inauguramos nuestro propio refugio en la ciudad de San Miguel, administrado por la Licda. Silvia Beatriz Ayala Granados. Aquí, brindamos un hogar temporal a perros abandonados, maltratados y en condiciones críticas, asegurándonos de que reciban el cuidado y atención necesarios para su rehabilitación.
                    </p>
                    <p className='text-lg font-light text-gray-800'>
                        Nuestro refugio se sostiene completamente gracias a las donaciones altruistas de personas comprometidas con nuestra causa. Estas donaciones incluyen alimentos, insumos de limpieza y apoyo económico. Uno de nuestros programas clave es el Plan Padrino, mediante el cual, por una contribución mensual de US$10.00, los padrinos pueden ayudar a cubrir los gastos de cuidado de un perro, incluyendo alimentación, vitaminas, desparasitaciones, vacunas, y más.
                    </p>
                    <p className='text-lg font-light text-gray-800'>
                        Además de los cuidados básicos, las donaciones también ayudan a mantener el albergue, cubriendo costos de limpieza, alquiler, servicios públicos y salarios de empleados, así como los servicios de profesionales veterinarios.
                    </p>
                    <p className='text-lg font-light text-gray-800'>
                        Actualmente, contamos con más de 100 perros listos para ser adoptados, todos ellos habiendo pasado por un riguroso proceso de curación y rehabilitación. Llevamos a cabo rescates de perros en condiciones críticas, como atropellados, perras embarazadas, perros con tumores/cáncer y aquellos con sarna.
                    </p>
                    <p className='text-lg font-light text-gray-800'>
                        Facilitamos las donaciones a través de transferencias bancarias y aplicaciones móviles, haciendo más fácil para nuestros benefactores contribuir a nuestra causa. En Échame una Pata SV, cada donación y cada acto de apoyo nos acerca más a nuestra misión de ofrecer una vida mejor a los animales necesitados.
                    </p>
                </div>
            </div>
        </div>
        <Footer />
    </>
  )
}
