import React from 'react'
import { Nav } from '../parts/nav'
import { pp1, apd1, apd2 } from '../components/imagenes';


import '../styles/carrusel.css';
import '../styles/index.css';
import { Footer } from '../parts/footer';

export const Dogs = () => {
  return (
    <>
      <Nav />
      <section className='w-full h-3/4 relative bg-gray-100' >
        <div className='h-full w-full relative'>

          <div className='absolute w-full h-full md:flex backgroundimg' style={{backgroundImage:`url(${pp1})`}}></div>
          <div className='absolute w-full h-full md:flex' id="subimg"></div>

          <div className='absolute w-full h-full flex justify-center items-center'>
            <span className='text-6xl text-white text-center font-semibold'>Plan Padrino</span>
          </div>

        </div>
      </section>
      <div className='w-full'>
          
          <div className='w-full h-5/6 py-6 flex justify-center items-center bg-gray-100'>
            <div className='md:w-9/12 w-full h-full p-6 flex mt-12'>
            
              <div className='hidden md:flex lg:flex bg-center bg-cover bg-center bg-cover' style={{backgroundImage:`url(${apd1})`, width:'600px', height:600}}>

              </div>

              <div className='md:w-1/2 w-full h-full flex justify-center items-center tracking-wide flex flex-col'>
                
                <div className='w-full h-1/6 py-5 flex items-center justify-center'>
                  <span className='md:text-4xl mx-5 text-2xl font-normal text-gray-900'> Unete a nuestro plan padrino </span>
                </div>

                <div className='h-5/6 flex flex-col'>
                  <p className='md:text-2xl m-5 text-lg font-light text-gray-800 font-extralight'>
                  Contribuye con $10 mensuales y transforma la vida de un perro rescatado. Al unirte al Plan Padrino, tu donación ayudará a cubrir los costos de alimentación, atención veterinaria y cuidados generales de un perro en nuestro refugio.
                  </p>
                  <p className='md:text-2xl m-5 text-lg font-light text-gray-800 font-extralight' >
                     A cambio, recibirás actualizaciones regulares sobre el bienestar del perro que estás ayudando y la satisfacción de saber que estás haciendo una diferencia significativa en su vida.
                  </p>
                </div>

              </div>

            </div>
          </div>
          
          <div className='w-full h-5/6 py-6 flex justify-center items-center bg-gray-100'>
            <div className='md:w-9/12 w-full h-full p-6 flex mt-12'>

              <div className='md:w-1/2 w-full  h-full flex justify-center items-center tracking-wide flex flex-col'>
                
                <div className='w-full h-1/6 py-5 flex items-center justify-center'>
                  <span className='md:text-4xl mx-5 text-2xl font-normal text-gray-900'>Beneficios del Plan Padrino </span>
                </div>

                <div className='h-5/6 flex flex-col'>
                  <div className="grid grid-cols-1 gap-6">

                    <div className="bg-white p-6 rounded-lg shadow-lg">
                      <h3 className="text-2xl font-normal mb-4 text-gray-900">Para el Perro</h3>
                      <ul className="list-disc list-inside">
                        <li className='text-lg font-light text-gray-800'>Alimentación balanceada</li>
                        <li className='text-lg font-light text-gray-800'>Atención veterinaria</li>
                        <li className='text-lg font-light text-gray-800'>Vacunas y desparasitaciones</li>
                        <li className='text-lg font-light text-gray-800'>Medicamentos y suplementos</li>
                        <li className='text-lg font-light text-gray-800'>Un lugar seguro y limpio</li>
                      </ul>
                    </div>

                    <div className="bg-white p-6 rounded-lg shadow-lg">
                      <h3 className="text-2xl font-normal mb-4 text-gray-900">Para el Padrino</h3>
                      <ul className="list-disc list-inside">
                        <li className='text-lg font-light text-gray-800'>Satisfacción de ayudar a un animal necesitado</li>
                        <li className='text-lg font-light text-gray-800'>Recibir actualizaciones sobre el perro apadrinado</li>
                        <li className='text-lg font-light text-gray-800'>Invitaciones a eventos exclusivos</li>
                        <li className='text-lg font-light text-gray-800'>Posibilidad de visitas al refugio</li>
                        <li className='text-lg font-light text-gray-800'>Certificado de apadrinamiento</li>
                      </ul>
                    </div>

                  </div>
                </div>

              </div>

              <div className='hidden md:flex lg:flex bg-center bg-cover w-1/2 h-full bg-center bg-cover' style={{backgroundImage:`url(${apd2})`, width:'600px', height:600}}>

              </div>

            </div>
          </div>
          <div>


          <div className='h-3/5 w-full flex justify-center items-center bg-gray-100'>

            <div className="w-11/12 h-full p-10 bg-white rounded">
              <h2 className="text-4xl text-gray-900 py-3 font-normal text-center mb-6">Cómo Funciona</h2>
              <div className="flex flex-col md:flex-row md:space-x-10">
                <div className="flex-1 mb-6 md:mb-0">
                  <h3 className="text-2xl font-normal text-gray-900 mb-4">Paso 1: Elige un Perro</h3>
                  <p className='text-lg font-light text-gray-800'>Explora nuestra galería de perros y elige a aquel que deseas apadrinar. Cada perro tiene una historia única y necesita tu ayuda.</p>
                </div>
                <div className="flex-1 mb-6 md:mb-0">
                  <h3 className="text-2xl font-normal text-gray-900 mb-4">Paso 2: Inscríbete</h3>
                  <p className='text-lg font-light text-gray-800'>Rellena el formulario de inscripción con tus datos personales y la elección del perro que deseas apadrinar.</p>
                </div>
                <div className="flex-1">
                  <h3 className="text-2xl font-normal text-gray-900 mb-4">Paso 3: Contribuye Mensualmente</h3>
                  <p className='text-lg font-light text-gray-800'>Realiza tu contribución mensual de $10. Recibirás actualizaciones periódicas sobre el estado y bienestar del perro apadrinado.</p>
                </div>
              </div>
            </div>

          </div>

            {/* <section className="bg-gray-100 p-14">
                <h2 className="text-4xl text-gray-900 py-3 font-normal text-center mb-6">Historias de Éxito</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <img src="https://via.placeholder.com/150" alt="Perro 1" className="mb-4 rounded-lg" />
                    <h3 className="text-2xl font-normal text-gray-900 mb-4">Max</h3>
                    <p className='text-lg font-light text-gray-800'>Max fue rescatado y rehabilitado gracias a la ayuda de su padrino. Ahora vive feliz en un hogar amoroso.</p>
                  </div>
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <img src="https://via.placeholder.com/150" alt="Perro 2" className="mb-4 rounded-lg" />
                    <h3 className="text-2xl font-normal text-gray-900 mb-4">Luna</h3>
                    <p className='text-lg font-light text-gray-800'>Luna encontró una nueva oportunidad de vida gracias al apoyo recibido del Plan Padrino.</p>
                  </div>
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <img src="https://via.placeholder.com/150" alt="Perro 3" className="mb-4 rounded-lg" />
                    <h3 className="text-2xl font-normal text-gray-900 mb-4">Buddy</h3>
                    <p className='text-lg font-light text-gray-800'>Buddy recibió el cuidado y amor necesarios para superar sus problemas de salud y encontrar un nuevo hogar.</p>
                  </div>
                </div>
            </section> */}

            <section className="bg-blue-500 text-white mt-10 p-10 text-center">
                <h2 className="text-3xl font-bold mb-6">¡Apadrina a un Perro Hoy!</h2>
                <p className="text-xl mb-6">Tu contribución de $10 mensuales puede hacer una gran diferencia en la vida de un perro rescatado.</p>
                <a href='/contacto' className="px-6 py-3 bg-white text-blue-500 font-bold rounded-lg hover:bg-gray-200">
                  Contactarse
                </a>
            </section>

          </div>

        </div>
      <Footer></Footer>


      
    </>
  )
}
