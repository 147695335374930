import React from 'react'


export const Nav = () => {

  const scrollToSection = (id) => {
    if (document.getElementById(id) == null) {
      window.location.assign("/");
    }else{
      document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    }
  };

  let clases = "text-black md:text-xl sx:text-base hover:text-gray-700 font-semibold md:px-6 md:mx-5 px-2 ";

  return (
    <>
      <nav className="h-auto min-h-24 w-full bg-white absolute z-50 p-2 grid grid-cols-12 gap-0">
        <div className='mr-5'>
          <div className='hidden w-20 h-20 md:flex justify-center items-center cursor-pointer' onClick={() => scrollToSection('home')}>
            <svg xmlns="http://www.w3.org/2000/svg" className='p-3' width="100" height="100" version="1.0" viewBox="0 0 1280 1232"><path fill="#541E1E" d="M844.3 1.9C771.8 19 715.1 79.1 687.7 168c-12.8 41.5-18.4 93-14.2 130.5 8.2 74.1 43.2 132.2 95.1 158.3 29.4 14.8 58.5 17.3 85.9 7.6 19.2-6.8 45.1-22 62-36.4 44.9-38.1 76.9-96.8 88.9-163 4.1-22.7 5.1-34.2 5-59.5 0-48.4-6.8-83.7-21.9-115-23-47.6-59.4-78.8-102.7-88.1-11.9-2.5-31.7-2.7-41.5-.5zM382 6.5c-40.1 7-77.9 38.6-100.4 83.9-12.3 24.7-18.1 48.4-20.7 84.7-1.2 15.9-.6 41.4 1.2 57.4 10.3 90.8 52.7 167.3 116.6 210.1 12.8 8.6 34 19.1 48.4 23.9 10.2 3.4 12.8 3.8 24.1 4.3 15 .6 25.7-.8 38.8-5 20.3-6.5 36.9-16.7 52.5-32.3 30.9-30.9 50.1-77.5 54.6-132.3 1.8-22.6-.2-56.2-5.1-82.5C572.4 112 511.1 33 429.9 9.6 413.7 5 397.1 3.9 382 6.5zM1140.3 371.4c-66.9 11-125.1 57.1-161.4 128-16.7 32.7-25.1 59.3-31.6 100.7-2.3 14.8-2.7 20.4-2.7 37.4 0 20.8 1.2 31.1 5.5 47.9 11.5 45.1 38.6 81.8 72.3 98.1 18.4 8.9 35.2 12.2 54.1 10.5 45.9-4 86.8-24.7 123.2-62.3 40.1-41.5 67.7-98.9 76.7-159.2 2.3-15 3.9-44.6 3.2-56.7-3.7-62.2-37.1-116.9-83.6-136.9-18-7.7-37.8-10.4-55.7-7.5zM105.1 397.6c-43.5 7.3-80.1 37.7-93.6 77.9-4.8 14.2-8.8 34.5-10.5 53-6.2 67.1 17.4 142.9 61.8 198.5 33.2 41.5 76 70.2 123.9 83.1 40.3 10.8 76.5.6 107.2-30.1 27.4-27.4 41.1-67.4 41.1-120.1 0-20.5-1-31.7-4.6-50.7-12.2-65.2-49.2-127.6-99.9-168.5-23.3-18.8-54.7-36.1-74.5-41-15.6-3.9-35.3-4.7-50.9-2.1zM624 641.5c-34.8 3.9-69.2 13.2-103.1 28.1-51.8 22.9-101.8 61-134.1 102.4-69.7 89.4-118.1 186.2-137.8 275.5-1.2 5.5-3.3 13.8-4.6 18.5-6.7 24.3-5.6 51.9 3.1 76 14 38.8 44.3 68.9 81.8 81.5 22.4 7.5 55.9 7.4 93.2-.4 24.3-5 42.5-10.8 94.1-29.9 71.8-26.7 91.7-31.8 140-36.3 21.6-2 25.5-2.1 40.4-1 38.1 2.7 68.2 11 126 34.6 51.2 20.9 79.8 32.2 87 34.2 72 20.4 143.5-4.7 168-59 10.7-23.9 11.9-62.8 2.9-99.7-8.8-35.9-34.9-94.2-59.9-133.5-70.5-111-120.8-172.7-179-219.7-54.7-44.1-100.6-65-157.1-71.3-15.9-1.8-44.7-1.8-60.9 0z"/></svg>
          </div>
        </div>
        <ul className="flex justify-center items-center space-x-4 col-span-10">
          <li>
            <button
              onClick={() => scrollToSection('home')}
              className={clases}
              style={{color:'#562d28'}}
            >
              Nosotros
            </button>
          </li>
          <li>
            <button
              onClick={() => scrollToSection('servicios')}
              className={clases}
              style={{color:'#562d28'}}
            >
              Servicios
            </button>
          </li>
          <li>
            <button
              onClick={() => scrollToSection('galeria')}
              className={clases}
              style={{color:'#562d28'}}
            >
              Galeria
            </button>
          </li>
          <li>
            <button
              onClick={() => scrollToSection('contacto')}
              className={clases}
              style={{color:'#562d28'}}
            >
              Contacto
            </button>
          </li>
        </ul>
      </nav>
    </>
  )
}
