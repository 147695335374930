import { sb1 } from "../components/imagenes"

export const SectionHome = () => {
  return (
    <section className='h-full w-full relative flex justify-center items-center bg-gray-100' id="home">

      <div className='absolute w-full h-full' id="image_poligon"></div>
      <div className='absolute w-full h-full hidden md:flex' id="sub_poligon"></div>
      
      <div className="absolute w-full h-full flex justify-center items-center">

        <div className='w-full md:w-4/5 md:h-4/6 h-full flex '>

          <div className='hidden lg:flex md:w-2/5 h-full rounded-lg bg-cover bg-center p-2' style={{backgroundImage:`url(${sb1})`}}>
          </div>
          
          <div className='w-full md:bg-transparent bg-blue-900/25 lg:w-3/5 h-full bg rounded-lg flex flex-col'>
            <div className='w-full h-1/6 py-5 flex items-center justify-center'>
              <span className='md:text-4xl mx-5 text-2xl font-semibold text-white'> SOBRE NOSOTROS </span>
            </div>
            <div className='h-5/6 flex flex-col'>
              <p className='lg:text-2xl m-5 text-xl text-white font-extralight'>
                Échame una Pata SV es un proyecto de rescate animal fundado en marzo de 2015, dedicado a apoyar a rescatistas independientes y refugios con alimento y medicinas. En diciembre de 2018, inauguramos nuestro refugio en San Miguel, administrado por la Licda. Silvia Beatriz Ayala Granados.
              </p>
              <p className='lg:text-2xl m-5 text-xl text-white mt-2 font-extralight'>
                Nuestro refugio se financia al 100% con donaciones y el Plan Padrino, que permite apadrinar un perro con US$10.00 mensuales, cubriendo sus necesidades básicas y los gastos del albergue. Contamos con más de 100 perros disponibles para adopción, todos ellos rehabilitados y listos para encontrar un hogar amoroso.
              </p>
              <p className='lg:text-2xl m-5 text-xl text-white mt-2 font-extralight'>
                Únete a nosotros y ayuda a mejorar la vida de estos animales.
              </p>
            </div>

          </div>

        </div>

      </div>
      
    </section>
  )
}
