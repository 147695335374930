import React, { useState, useCallback, useEffect } from 'react';
import { Nav } from '../parts/nav'
import { perro8 } from '../components/imagenes'
// import Gallery from 'react-photo-gallery';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import { Footer } from '../parts/footer'
import { GalleryCard } from '../components/gallery_card';
import { getData } from '../helpers/getImages';
// import PhotoAlbum from 'react-photo-album';


export const Galeria = () => {

  const [currentImage, setCurrentImage] = useState(-1);
  const [images, setImages] = useState([]);

  const getImages = async () => {
    const images = await getData();
    setImages(images);
  }

  useEffect(() => {
    getImages();
  }, [])
  
  const openLightbox = useCallback((index) => {
    setCurrentImage(index);
  }, []);
  return (
    <>
        <Nav />
        <section className='w-full h-3/4 relative bg-gray-100' >
          <div className='h-full w-full relative'>

            <div className='absolute w-full h-full md:flex backgroundimg' style={{backgroundImage:`url(${perro8})`}}></div>
            <div className='absolute w-full h-full md:flex' id="subimg"></div>

            <div className='absolute w-full h-full flex justify-center items-center'>
              <span className='text-6xl text-white font-semibold'>Galeria</span>
            </div>

          </div>
        </section>
        <section className="w-full bg-gray-100 flex justify-center items-center p-10">
          <div className='bg-white p-6 grid md:grid-cols-4 xs:grid-rows-4'>
            {
              images.map((item, index) => {
                return <GalleryCard key={index} onClick={() => openLightbox(index)} img={item.src} />
              })
            }

          </div>
            <Lightbox
              index={currentImage}
              slides={images.map(photo => ({ src: photo.src }))}
              open={currentImage >= 0}
              close={() => setCurrentImage(-1)}
            />
        </section>
      <Footer />
    </>
  )
}
