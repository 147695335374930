import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import {perro1, perro2, perro3, perro4, perro5, perro6} from './imagenes';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const Carrusel = () => {

  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty('--progress', 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };

  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 5500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={false}
        modules={[Autoplay, Pagination, Navigation]}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        className="mySwiper"
      >
        <SwiperSlide>
            <img src={perro1} alt="perro1" />
        </SwiperSlide>
        <SwiperSlide>
            <img src={perro2} alt="perro2" />
        </SwiperSlide>
        <SwiperSlide>
            <img src={perro3} alt="perro3" />
        </SwiperSlide>
        <SwiperSlide>
            <img src={perro4} alt="perro4" />
        </SwiperSlide>
        <SwiperSlide>
            <img src={perro5} alt="perro5" />
        </SwiperSlide>
        <SwiperSlide>
            <img src={perro6} alt="perro6" />
        </SwiperSlide>

        <div className="autoplay-progress" slot="container-end">
          <svg viewBox="0 0 48 48" ref={progressCircle}>
            <circle cx="24" cy="24" r="20"></circle>
          </svg>
          <span ref={progressContent}></span>
        </div>

      </Swiper>
    </>
  )
}

export default Carrusel;