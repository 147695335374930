import React from 'react'
import Header from './parts/header';
import './styles/carrusel.css';
import './styles/index.css';

import { Logo } from './svgs/logo';
import { Nav } from './parts/nav';

import { Footer } from './parts/footer';
import { SectionNosotros } from './sections/SectionNosotros';
import { SectionHome } from './sections/SectionHome';
import { SectionTimeline } from './sections/SectionTimeline';
import { SectionServicios } from './sections/SectionServicios';
import { SectionGaleria } from './sections/SectionGaleria';
import { SectionContacto } from './sections/SectionContacto';


export const App = () => {

  return (
    <>
    <div className='h-full w-full relative'>
      <Nav></Nav>
      <Logo></Logo>
      <Header></Header>
    </div>

    <SectionNosotros/>

    <SectionHome/>

    <SectionTimeline/>

    <SectionServicios/>

    <SectionGaleria/>

    <SectionContacto/>

    <Footer></Footer>
    </>
  )
}
