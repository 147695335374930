import React from 'react'
import { GalleryCard } from '../components/gallery_card'
import { g1, g2, g3, g4 } from '../components/imagenes'

export const SectionGaleria = () => {
  return (
    <section className='w-full lg:h-4/6 h-auto bg-gray-100 flex flex-col justify-center items-center' id="galeria">

      <div className="h-20 w-full flex justify-center items-center py-5">
        <span className="text-4xl p-5 m-5 font-bold text-gray-800">Galeria</span>
      </div>

      <div className="w-11/12 flex justify-center grid lg:grid-cols-4 sx:grid-rows-4 gap-4 bg-white place-content-between h-full p-10">

        <GalleryCard img={g1}/>
        <GalleryCard img={g2}/>
        <GalleryCard img={g3}/>
        <GalleryCard img={g4}/>        
         

      </div>

      <div className="w-full flex justify-center items-center py-5">
        <a href='/galeria' className="text-blue-500 text-normal text-xl hover:text-primary-600 focus:text-primary-600 active:text-primary-700 hover:underline">ver galeria</a>
      </div>
      


    </section>
  )
}
