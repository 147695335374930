import React from 'react'
import {logo} from '../components/imagenes'

export const Logo = () => {
  return (
    <>
    <div className='h-full w-full absolute z-10 md:flex flex justify-center items-center' /*style={{boxShadow:'0px -50px 60px -20px rgba(255,255,255,0.75) inset'}}*/>
      <div className='md:w-2/5 md:h-3/5 w-96 h-96 bg-red-50/25 rounded p-5 flex justify-center items-center glass' version="1.1" style={{ animation: 'balanceo 5s infinite' }}>
        <img src={logo} alt="" />
      </div>
    </div>
    </>
  )
}
