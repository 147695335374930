export const getData = async() => {
    const url = `https://backend.echameunapatasv.com/backend/getData.php`;
    const resp = await fetch(url);
    const data = await resp.json();

    const maper = data.map((item) => (
        { src: item, width: 4, height: 3 }
    ));

    return maper;
}
