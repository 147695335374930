import React from 'react'
import Carrusel from '../components/carrusel';


const Header = () => {
  return (
    <>
      <Carrusel></Carrusel>
    </>
  )
}

export default Header;
