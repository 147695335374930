import { Timeline } from "../components/timeline"

export const SectionTimeline = () => {
  return (
    <section className='h-auto w-full bg-gray-100 py-5'>
      <div className='h-20 w-full flex justify-center items-center py-5'>
        <span className='text-4xl p-5 m-5 font-bold text-gray-800'>RECORRIDO</span>
      </div>
      <Timeline/>
    </section>
  )
}
