import React from 'react';

const CustomCard = ({ title, description, buttonText, Image, funOnOlick }) => {
  return (
    
    <div className="m-5 lg:w-80 md:w-64 md:p-4 py-9 flex flex-col justify-center items-center bg-white rounded-lg shadow-md transform hover:scale-105 transition-transform duration-300 ease-in-out">
      {/* <div className='hidden md:flex w-full h-20'></div> */}
      <div className="p-5 rounded-full ring-offset-3 ring-2 flex justify-center items-center bg-white">
        {Image}
      </div>
      <div className="p-4">
          <h2 className="text-xl  font-semibold">{title}</h2>
          <p className="text-gray-600 text-base w-full h-min-20">{description}</p>
          <div className="flex justify-between items-center mt-6">
              <a href={funOnOlick} className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-400">{buttonText}</a>
          </div>
      </div>
    </div>
  );
};

const SimpleCard = ({ title, description, buttonText, funOnOlick }) => {
  return (
    <div className="md:w-96 xs:w-10 h-48 rounded-lg bg-white p-6 m-5 z-10 shadow-md">
        <h5
          className="mb-2 text-xl font-semibold leading-tight text-neutral-800">
          {title}
        </h5>
        <p className="text-base text-gray-600 w-full h-20">
          {description}
        </p>
          <a
            type="button"
            href={funOnOlick}
            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-400">
            {buttonText}
            
          </a>
      </div>
  );
}

export { CustomCard, SimpleCard };