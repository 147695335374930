import { SimpleCard } from "../components/cards"

export const SectionServicios = () => {
  return (
    <section className='h-auto w-full relative flex flex-col justify-center items-center bg-gray-100 p-14' id="servicios">

      <div className='absolute w-full h-full md:flex' id="subimg"></div>

      <h2 className="w-full h-20 text-4xl font-bold text-center mb-10 text-white z-10">Nuestros Servicios</h2>
      <div className="flex flex-wrap justify-center">
        <SimpleCard 
          title="Adopción"
          description="Encuentra tu nuevo mejor amigo entre nuestros perros disponibles para adopción."
          buttonText="Ver Peluditos"
          funOnOlick={"/galeria"}
          icon={"faPaw"}
        />
        <SimpleCard 
          title="Rescate"
          description="Rescatamos perros en situación de abandono o maltrato."
          buttonText="Contactanos"
          funOnOlick={"/contacto"}
          icon={"faHeart"}
        />
        <SimpleCard 
          title="Cuidado Veterinario"
          description="Proveemos atención veterinaria completa a nuestros animales rescatados."
          buttonText="Contactanos"
          funOnOlick={"/contacto"}
          icon={"faHandHoldingHeart"}
        />
      </div>


    </section>
  )
}
