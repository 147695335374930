import React, {useState} from 'react'
import { Nav } from '../parts/nav'
import { perro8 } from '../components/imagenes'
import { Footer } from '../parts/footer'

export const Contacto = () => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('/api/send-mail.js', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        console.log('Mensaje enviado correctamente');
      } else {
        console.log('Error al enviar el mensaje');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error al enviar el mensaje');
    }
  };

  return (
    <>
        <Nav />
        <section className='w-full h-3/4 relative bg-gray-100' >
          <div className='h-full w-full relative'>

            <div className='absolute w-full h-full md:flex backgroundimg' style={{backgroundImage:`url(${perro8})`}}></div>
            <div className='absolute w-full h-full md:flex' id="subimg"></div>

            <div className='absolute w-full h-full flex justify-center items-center'>
              <span className='text-6xl text-white font-semibold text-center'>Contacto</span>
            </div>

          </div>
        </section>

        <section className="text-gray-700 body-font relative">
          <form onSubmit={handleSubmit}>
            <div className="container px-5 mx-auto bg-white md:w-2/4 sx:full rounded m-5" style={{paddingTop:'30px',paddingBottom:'30px'}}>
              
              <div className="md:w-3/4 sx:w-full mx-auto">
                <div className="flex flex-wrap -m-2">

                  <div className='p-2 w-full text-center'>
                    <span className='text-4xl p-5 m-5 font-bold text-gray-800'>ENVIANOS UN CORREO</span>
                  </div>

                  <div className="p-2 w-1/2">
                    <div className="relative">
                      <label for="name" className="leading-7 text-sm text-gray-600">
                        Nombre
                      </label>
                      <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                    </div>
                  </div>

                  <div className="p-2 w-1/2">
                    <div className="relative">
                      <label for="email" className="leading-7 text-sm text-gray-600">
                        Email
                      </label>
                      <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                    </div>
                  </div>

                  <div className="p-2 w-full">
                    <div className="relative">
                      <label for="message" className="leading-7 text-sm text-gray-600">
                        Mensaje
                      </label>
                      <textarea id="message" name="message" value={formData.message} onChange={handleChange} required className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
                    </div>
                  </div>

                  <div className="p-2 w-full">
                    <button type="submit" className="flex mx-auto text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">
                      Enviar
                    </button>
                  </div>

                </div>
              </div>
            </div>

          </form>
        </section>

        <Footer></Footer>

    </>
  )
}
